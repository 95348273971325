import React, { useCallback, useEffect, useRef } from "react";
import { lazy } from "react";
import { HashRouter, Route, Switch, Redirect } from "react-router-dom";
import "./App.css";
import Login from "./pages/login/Login";
import { QueryClient, QueryClientProvider } from "react-query";
import { Loader } from "./components";
import { ContextProvider } from "./utils/Context";
import Profile from "./pages/proflie/Profile";
import { serviceApi } from "./api/Services";

const AppDashboard = lazy(() => import("./pages/AppDashboard/AppDashboard"));
const Dashboard = lazy(() => import("./pages/dashboard/Dashboard"));
const PoolDetails = lazy(() => import("./pages/inner/PoolDetails"));
const TermsPage = lazy(() => import("./pages/staticPage/TermsPage"));
const RequestForm = lazy(() => import("./pages/RequestForm"));

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

function App() {
  const intervalRef = useRef();
  const getRefreshToken = async (token) => {
		let res = await serviceApi.post('/auth/refreshAccessToken',{
			"refreshToken": token,
			
		  });
		 if(res.status !== false){
      localStorage.setItem("currentUser",JSON.stringify(res['data']['data']['data']));
      
		 }
   }
   const getToken = useCallback(() => {
		if (localStorage.getItem("currentUser") != null) {
      let data = JSON.parse(localStorage.getItem("currentUser") || "{}");
      //console.log("token",data);
			getRefreshToken(data.refreshToken);
		}
	}, []);
	useEffect(() => {
		const interval = setInterval(() => getToken(), 1000 * 60 * 2); // 10 minutes interval as our token will expire after 7 minutes.
		// intervalRef.current = interval || null;
   
    return () => clearInterval(interval);
	}, [getToken]);
  return (
    
    <QueryClientProvider client={queryClient}>
      <HashRouter>
        <ContextProvider>
          <React.Suspense fallback={<Loader />}>
            <Switch>
              <Route
                exact
                path="/"
                render={() => <Redirect to="/app" />}
              />
              <PrivateRoute path="/dashboard" component={Dashboard} />
              <PrivateRoute path="/profile" component={Profile} />
              <PublicRoute path="/app" component={AppDashboard} />
              <PublicRoute path="/login" component={Login} />
              <PublicRoute
                path="/pool-data-details/:id"
                component={PoolDetails}
              />
              <PublicRoute path="/terms" component={TermsPage} />
              <PublicRoute path="/request-form" component={RequestForm} />
            </Switch>
          </React.Suspense>
        </ContextProvider>
      </HashRouter>
    </QueryClientProvider>
  );

  function PublicRoute({ component, ...rest }: any) {
    return (
      <Route
        {...rest}
        render={(props) => React.createElement(component, props)}
      />
    );
  }
  function PrivateRoute({ component, ...rest }: any) {
    return localStorage.getItem("currentUser") ? (
      <Route
        {...rest}
        render={(props) => React.createElement(component, props)}
      />
    ) : (
      <Redirect to="/app" />
    );
  }
}

export default App;

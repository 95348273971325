import axios from "axios";
import {API_URL} from "./api";
export const serviceApi = {
  validateError,
  post,
  get,
  patch
};
function get(path,formdata={}){
    
    
  let axiosRequestOptions = {
      headers: {
      'Content-Type': 'application/json',
      
      }
  };
  let currentUser:any = localStorage.getItem("currentUser");
  //console.log('currentUser',currentUser)
  if(currentUser){
      currentUser = JSON.parse(currentUser);
      axiosRequestOptions['headers']['Authorization'] = 'Bearer '+currentUser.accessToken;
      formdata['token'] = currentUser.accessToken;
      //formdata['user_id'] = currentUser.user_id;
      axiosRequestOptions['headers']['data'] = JSON.stringify(formdata);
  }
  
 
  return axios.get(`${API_URL}${path}`,axiosRequestOptions)
  .then(response=> {
     
      return ({status:true,data:response.data});
  })
  .catch(error=> {
     
      return({status:false,error:error});
  });


}
function post(path,formdata={}){
  var headers= {'Content-Type': 'application/json'};
  let loginUser:any = localStorage.getItem("currentUser");
  
  if(loginUser){
      let currentUser = JSON.parse(loginUser);
      headers['Authorization'] = 'Bearer '+currentUser.accessToken;
   }
  
  return axios.post(`${API_URL}${path}`,formdata,{headers})
  .then(response=> {
          console.log(response)
         return ({status:true,data:response});
  })
  .catch((error)=> {
     return({status:false,error:error.response});
  });

}
function patch(path,formdata={}){
  var headers= {'Content-Type': 'application/json'};
  let loginUser:any = localStorage.getItem("currentUser");
  
  if(loginUser){
      let currentUser = JSON.parse(loginUser);
      headers['Authorization'] = 'Bearer '+currentUser.accessToken;
   }
  
  return axios.patch(`${API_URL}${path}`,formdata,{headers})
  .then(response=> {
          console.log(response)
         return ({status:true,data:response});
  })
  .catch((error)=> {
     return({status:false,error:error.response});
  });

}
function validateError(formData){
  let errors = {};
   Object.keys(formData).forEach(element=>{
      if(formData[element] === ""){
          errors[element]=true;
      }
  })
  return errors
}
import { alpha, createStyles, makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme: any) =>
  createStyles({

    root:{
      "&.focus-visible": {
        border: "none",
        outline:"none",
        '& .MuiOutlinedInput-notchedOutline': {
          border: 'none'
        }
      }
    },
    logo_wrapper:{
      margin: "auto",
      padding:"72px 0px 60px",
      borderBottom: "1px solid rgba(72, 110, 136, 0.3)",
      width: "100%",
      textAlign: "center",

      "& h6":{
        paddingLeft: "15px",
      },
    },
    app_footer_logo:{
      width: "280px",
      margin:"0px auto",
    },
    footer_wrapper:{
      textAlign: "left",
      padding: "30px 0px 20px 0",
      width: "100%",
      justifyContent: "space-between",

      "@media (max-width: 767px)": {
        padding: "30px 0px 0px 0",
      },
    },
    footer_content:{
      display: "flex",
      justifyContent: "space-between",

      "@media (max-width: 815px)": {
        flex: 1,
        marginLeft: "20px",
        flexWrap: "wrap",
        gap: "unset",
        justifyContent: "space-between",
      },
      "@media (max-width: 767px)": {
        marginLeft: 0,
      },
    },
    footer_content_items:{
      display: "flex",
      flexDirection: "column",
      minWidth: "130px",

      "& h4":{
        fontFamily: 'Roboto',
        fontStyle: "normal",
        fontWeight: 700,
        fontSize: "24px",
        lineHeight: "52px",
        textTransform: "capitalize",
        color: "#FFFFFF",
        margin:"0px 0px 10px",
      },
      "& a":{
        padding: "0px 0px",
        textDecoration: "none",

        "& span":{
          fontStyle: "normal",
          fontWeight: "400",
          fontSize: "18px",
          lineHeight: "42px",
          color: "#FFFFFF",
          display: "flex",
          alignItems: "center",

          "@media (max-width: 767px)": {
            justifyContent: "center",
            alignItems: "center",
          },
        },
        "& svg":{
          marginRight: "16px",
        },
        "& img":{
            marginRight: "18px",
            width: "20px",
        }
      },
      "@media (max-width: 767px)": {
        width: "100%",
        textAlign: "center",
        marginBottom: "30px",
      },
    },
    chainLabel: {
      marginRight: 10,
      position:"absolute",
      top:"-10px",
      zIndex:11,
      left:"15px",
      color:"rgb(255 153 0)",
      background:"#011422",
      // "@media (max-width: 370px)": {
      //   display: "none",
      // },
    },
    Register:{
      fontStyle:" normal",
      fontWeight:'bold',
      textTransform:'capitalize',
      fontFamily:'Archivo !important',
      fontSize:"19.8983px",
      lineHeight:"22px",
      color:"#FFFFFF",
      textDecoration:"none",
      background: "linear-gradient(80.04deg, #FAB013 5.55%, #FF2E00 90.36%)",
      borderRadius:"244px",
      width:"155px",
      display:"block",
      textAlign:"center",
      padding:"16px 0px",
      "@media (max-width: 900px)": {
        display: "none",
      },
    },
    RegisterMob:{
      fontStyle:" normal",
      fontWeight:'bold',
      textTransform:'capitalize',
      fontFamily:'Archivo !important',
      fontSize:"14px",
      lineHeight:"18px",
      color:"#FFFFFF",
      textDecoration:"none",
      background: "linear-gradient(80.04deg, #FAB013 5.55%, #FF2E00 90.36%)",
      borderRadius:"244px",
      width:"120px",
      display:"block",
      textAlign:"center",
      padding:"16px 0px",
    },
    dashboardmenu:{
        fontStyle:" normal",
        fontWeight:700,
        fontSize:"19.8983px",
        lineHeight:"22px",
        color:"#708EA4",
        marginLeft:25,
        textDecoration:"none",
        "@media screen and (min-width: 901px) and (max-width: 1100px)": {
          marginLeft:20,
        },
      "@media (max-width: 900px)": {
        display: "none",
      },
    },
    chainSelect: {
      color: "#fff",
      fontSize: 16,
      marginRight: 6,
      background: "#011422",
      width: "120px",
      borderRadius:"30px",
      padding:" 7px 0px",

      
      "& .MuiInput-underline:before":{
        borderBottom: '1px solid rgb(0 0 0 / 0%)',
        display:'none',
      },
      "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
        borderBottom: '0px none',
        display:'none',
      },
      "& .MuiInput-underline:after": {
        display:'none',
      },
        "& svg": {
          color: "#fff",
        },        
      "@media (max-width: 575px)": {
        minWidth: 100,
        width: 100,
        padding:" 3px 0px",
      },
    },
    chainselected:{
      position:"relative",
      margin:"0px 51px",
      borderRadius:"38px",
      width:"120px",
      textAlign: "center",
      padding:"1px 1px",
      background: "linear-gradient(80.04deg, #FAB013 5.55%, #FF2E00 90.36%);",
      "& .MuiInput-underline:before":{
        borderBottom: '1px solid rgb(0 0 0 / 0%)',
        display:'none',
      },
      "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
        borderBottom: '0px none',
      },
      "& .MuiInput-underline:after": {
        display:'none',
      },
      "@media screen and (min-width: 901px) and (max-width: 1100px)":{
        margin:'0 20px',
      },
      "@media (max-width: 900px)": {
        margin: "1px 5px",
        padding: '1px 1px',
        width:"120px",
      },
      "@media (max-width: 550px)": {
        margin: "1px 5px",
        padding: '1px 1px',
        width:"100px",
      },
    },
    header: {
      position: "fixed",
      top: "0",
      background: "#011422",
      padding: "20px 40px",
      boxShadow:"none",
      "@media (max-width:768px)": {
        padding: "0 15px",
      },
      "@media (max-width:575px)": {
        padding: "14px 0",
        maxWidth:"425px",
        "& .MuiToolbar-regular": {
          paddingRight: 0,         
        },
      },
      "@media (max-width:1025px) ": {
        padding: "14px 0",
        maxWidth:"1024px",
      },
      "@media (max-width:821px) ": {
        padding: "14px 0",
        maxWidth:"820px",
      },
      "@media (max-width:770px) ": {
        padding: "14px 0",
        maxWidth:"768px",
      },
      "@media (max-width:426px)": {
        maxWidth:"425px",
      },
      "@media (max-width:415px)": {
        maxWidth:"414px",
      },
      "@media (max-width:391px)": {
        maxWidth:"390px",
      },
      "@media (max-width:380px)": {
        maxWidth:"375px",
      },
      "@media (max-width:350px)": {
        maxWidth:"320px",
      },

      "& .MuiListItem-root a": {
        display: "flex",
        fontSize: "14px",
        color: "rgba(255, 255, 255, 0.9)",
        alignItems: "center",
        textDecoration: "none",
      },
      "& .MuiListItem-root a svg": {
        marginRight: "5px",
      },
      "& .MuiList-root": {
        "@media (max-width:768px)": {
          display: "none",
        },
      },
      "& img": {
        width: "280px",
        marginRight: "10px",
        "@media (max-width:550px)": {
          width: "210px",
        },
        "@media (max-width:400px)": {
          width: "185px",
        },
        "@media (max-width:360px)": {
          width: "145px",
        },
      },
    },
    withlogin: {
      "& svg": {
        background: "rgba(230, 231, 233, 0.04)",
        borderRadius: "4px",
      },
    },
    logoIcon: {
      flex: "0 0 27%",
      display: "flex",
      alignItems: "center",
      cursor:'pointer',
    },
    menuItem: {
      flex: "0 0 46%",
      "@media (max-width:768px)": {
        flex: "auto",
      },
    },
    innermenu: {
      display: "flex",
    },
    title: {
      marginLeft: "13px",
      marginRight: "8px",
    },
    sectionDesktop: {
      display: "none",
      [theme.breakpoints.up("md")]: {
        display: "flex",
        alignItems: "center",
      },
    },
    chipIcon: {
      "& Chip": {
        background: "#4A4DE6",
      },
    },
    chipinner: {
      background: "#4A4DE6",
      color: "#fff",
    },
    chipbtn: {
      background:
        "linear-gradient(270deg, #6231ED -9.12%, #4A4DE6 24.42%, #3A69DC 67.62%, #7ED6C4 122.3%)",
      color: "#fff",
      fontSize: "14px",
      padding: "6px 12px",
      "& svg": {
        fill: "#fff",
        marginLeft: "0px",
        marginRight: "0px",
      },
      "& span": {
        paddingRight: "0px",
        paddingLeft: "6px",
      },
    },
    chipwallet: {
      // color:"#fff",
      //background:' rgba(230, 231, 233, 0.04)',
      width: "32px",
      height: "32px",
      padding: "4px",
      marginLeft: "-11px",
      //borderRadius:'4px'
    },
    listmenu: {
      paddingLeft: "10px",
      paddingRight: "10px",
      justifyContent: "center",
      "& svg": {
        marginRight: "5px",
      },
    },
    menuactive: {
      padding: "0",
      "& .navbar-item": {
        borderRadius: "4px",
        padding: "8px 16px",
      },
      "& .active": {
        background: "rgba(230, 231, 233, 0.08)",
      },
    },
    footer: {
      background: "#011422",
      "& Button": {
        marginLeft: "0",
        marginRight: "0",
        fontSize: "14px",
        fontWeight: "normal",
        color: "rgba(255, 255, 255, 0.7)",
        textTransform: "initial",
      },
      "& .MuiToolbar-root": {
        "@media (max-width:768px)": {
          padding: "0",
          maxWidth: "100%",
        },
      },
      "& .MuiTypography-root": {
        "@media (max-width:768px)": {
          width: "100%",
          textAlign: "center",
        },
      },
    },
    footerinner: {
      // width: "100%",
      // maxWidth: "1280px",
    },
    fttitle: {
      border:"1px solid rgba(72, 110, 136, 0.3)",
      textAlign: "center",
      padding:" 30px 0px 80px",
      fontFamily: "Roboto",
      fontStyle: "normal",
      fontWeight:700,
      fontSize:" 18px",
      lineHeight:"52px",
      textTransform:"capitalize",
      color:" #FFFFFF",
      opacity: "0.6",
      marginTop:" 20px",
      "@media (max-width: 768px)": {
        // textAlign: "left !important",
        width: '100%',
        fontSize: '16px',
        fontWeight: '400',
        textAlign: 'center !important',
        padding: '10px 0px 20px',
      },
    },

    logotype: {
      color: "white",
      marginLeft: theme.spacing(2.5),
      marginRight: theme.spacing(2.5),
      fontWeight: 500,
      fontSize: 18,
      whiteSpace: "nowrap",
    },
    appBar: {
      width: "100vw",
      zIndex: theme.zIndex.drawer + 1,
    },
    toolbar: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
    grow: {
      flexGrow: 1,

      "& .withlogin": {
        display: "flex",
        alignItems: "center",
      },
    },
    headerMenuButton: {
      marginLeft: theme.spacing(2),
      padding: theme.spacing(0.5),
    },
    headerIcon: {
      fontSize: 28,
      color: "rgba(255, 255, 255, 0.35)",
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
    search: {
      position: "relative",
      borderRadius: theme.shape.borderRadius,
      backgroundColor: alpha(theme.palette.common.white, 0.15),
      "&:hover": {
        backgroundColor: alpha(theme.palette.common.white, 0.25),
      },
      marginRight: theme.spacing(2),
      marginLeft: 0,
      width: "100%",
      [theme.breakpoints.up("sm")]: {
        marginLeft: theme.spacing(3),
        width: "auto",
      },
    },
    searchIcon: {
      padding: theme.spacing(0, 2),
      height: "100%",
      position: "absolute",
      pointerEvents: "none",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    inputRoot: {
      color: "inherit",
    },
    inputInput: {
      padding: theme.spacing(1, 1, 1, 0),
      // vertical padding + font size from searchIcon
      paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
      transition: theme.transitions.create("width"),
      width: "100%",
      [theme.breakpoints.up("md")]: {
        width: "20ch",
      },
    },
    notifications: {},
    sectionMobile: {
      display: "flex",
      [theme.breakpoints.up("md")]: {
       display: "none",
      },
      paper: {},
    },
    watchicon: {
      padding: "4px 4px",
      background: "rgba(230, 231, 233, 0.04)",
      borderRadius: "4px",
      marginLeft: "12px",
      marginRight: "12px",
      width: "32px",
      height: "32px",
      color: "rgba(255, 255, 255, 0.9)",
      "& svg": {
        width: "16px",
        height: "16px",
      },
    },
    activitieList: {
      background: "#1A1A23",
      border: "1px solid #242636",
      borderRadius: "8px",
      paddingTop: "20px",
      color: "#fff",
      "& .MuiListItem-root": {
        display: "inline-block",
        padding: "0 20px",
        borderBottom: "solid 1px #242636",
        paddingBottom: "18px",
        paddingTop: "12px",
      },
      "& .MuiListItem-gutters p": {
        paddingLeft: "0px",
      },
      "& .MuiTypography-body1": {
        paddingLeft: "20px",
      },
      "& .MuiListItem-root p": {
        fontSize: "14px",
      },
      "& .MuiTypography-h4": {
        fontSize: "14px",
        margin: "8px 0 14px 0",
      },
      "& .MuiList-root": {
        paddingTop: "12px",
        paddingBottom: "0",
        scrollbarWidth: "thin",
        overflowY: "scroll",
        paddingRight: "0",
        height: "425px",
        display: "grid",
        alignItems: "center",
      },
      "& .MuiLink-root": {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        paddingTop: "10px",
        textDecoration: "none",
        color: "#4A4DE6",
      },
      "& .MuiLink-root img": {
        marginLeft: "4px",
      },
      "& ::-webkit-scrollbar": {
        width: "4px",
        borderRadius: "10px",
      },
      "& ::-webkit-scrollbar-track": {
        background: "#1A1A23",
      },
      "& ::-webkit-scrollbar-thumb": {
        background: "rgba(255, 255, 255, 0.32)",
      },
      "& .nodata": {
        textAlign: "center",
        padding: "105px 0",
      },
    },

    topdate: {
      color: "rgba(255, 255, 255, 0.42)",
      "& small": {
        color: "rgba(255, 255, 255, 0.9)",
        float: "right",
        fontSize: "14px",
      },
    },

    iconList: {
      display: "flex",
      alignItems: "center",
      "& img": {
        marginRight: "8px",
      },
    },
    droplink: {
      textAlign: "center",
      "& .MuiTypography-root": {
        color: "#4A4DE6",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      },
      "& img": {
        marginLeft: "5px",
      },
    },
    activitiemain: {
      width: "324px",
      marginTop: "8px",
      marginRight: "50px",
      zIndex: 9999,
    },

    rigthIconsHeader: {
      display: "flex",
      "& img": {
        width: "18px",
      },
    },
    mianHeaderIcon: {
      width: "40px",
      height: "40px",
      background: "rgba(230, 231, 233, 0.04)",
      borderRadius: "4px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      marginLeft: "10px",
      position: "relative",
      cursor: "pointer",
      "& img": {
        marginRight: "0px",
      },
      "& svg.MuiSvgIcon-root": {
        width: "19px",
        height: "19px",
      },
    },
    countercartHeader: {
      background: "#FFA000",
      minWidth: "20px",
      height: "18px",
      color: "#181819",
      fontSize: "12px",
      position: "absolute",
      borderRadius: "50px",
      textAlign: "center",
      lineHeight: "18px",
      top: "-5px",
      right: "-6px",
    },
    cartItemList: {},
    headerCart: {
      padding: "0px 20px",
      marginBottom: 12,
      display: "block",
    },
    cartMenu: {
      width: 334,
      padding: "12px 20px",
      borderBottom: "solid 1px #242636",
      display: "block",
      "& span": {
        marginLeft: 14,
        lineHeight: "32px",
        fontSize: 14,
      },
      "& img": {
        float: "left",
      },
      "& i": {
        float: "right",
        margin: "10px 0",
      },
    },
    cartItemData: {
      "& .MuiPopover-paper": {
        background: "#1A1A23",
        color: "#fff",
        top: "60px !important",
      },
      "& .MuiList-root": {
        paddingTop: 20,
        paddingBottom: 0,
      },
    },
    cartlink: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      padding: 0,
      "& a": {
        fontSize: 14,
        color: "#4A4DE6",
        marginTop: 10,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        textDecoration: "none",
        marginBottom: 15,
      },
      "& .MuiSvgIcon-root": {
        width: 18,
      },
    },
    mobileMenuTo: {
      "& .MuiPopover-paper": {
        background: "#1A1A23",
        borderRadius: 5,
        top: "63px!important",
        border: "solid 1px hsl(0deg 0% 100% / 9%)",
      },
      "& a.navbar-item": {
        display: "flex",
        alignItems: "center",
        width: 250,
        color: "#fff",
        textDecoration: "none",
      },
      "& .MuiSvgIcon-root": {
        marginRight: 8,
      },
      "& .MuiListItem-button": {
        padding: "10px 15px",
        borderBottom: "solid 1px hsl(0deg 0% 100% / 9%)",
      },
      "& .MuiMenu-list": {
        padding: 0,        
      },
      "& .MuiButtonBase-root": {
        color: "#fff",
      },
    },
   
    registerDialog:{
      "& .MuiDialog-paper":{
        background: "#123752",
        boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
        borderRadius: "20px",
        padding: "35px",
        width: "600px",
        boxSizing:"border-box",
        paddingRight:'20px',
        position:'relative',

        "@media (max-width:576px)": {
          padding: "30px",
          paddingRight:'0px',
        },
      },
      "& .MuiDialogContent-root":{padding:0, overflowX:"hidden", paddingRight:'30px',},
      "& h3":{
        fontWeight: 700,
        fontSize: "26px",
        lineHeight: "28px",
        color: "#708EA4",
        marginTop:0,
        marginBottom: "15px",
      },
      "& .subtitle":{
        fontWeight: 500,
        fontSize: "16px",
        lineHeight: "18px",
        color: "rgba(112, 142, 164, 0.5)",        
      },
      "& .subtitlespan":{
        color:"#FD4C04",
        marginTop: 0,
        marginBottom: "20px",
        cursor: "pointer",
      },
    },
    registerformControl:{
      width: "100%",
      maxWidth: "500px",     
      background: "#032137",
      border: "1.4783px solid #20425A",     
      borderRadius: "8px",
      position: 'relative',
      "& .MuiInput-underline:before":{
        borderBottom: '1px solid rgb(0 0 0 / 0%)',
      },
      "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
        borderBottom: '0px none',
      },
      "& img":{
        width:"17px",
        height:"17px",
        position:"absolute",
        top: "15px",
        left: "10px",
        cursor: "pointer",
      },
      
      "& input":{
        height: "46px",
        boxSizing: "border-box",
        padding: "10px 40px",
        fontWeight: 400,
        fontSize: "14px",
        lineHeight: "16px",
        color: "#285271",
        position:"relative",

        "&::-webkit-calendar-picker-indicator":{
          fill:"#285271",
          fontSize:"20px",
          filter: 'invert(0.5)', 
          position:"absolute",  
          top:"12px",
          left:"7px",
          zIndex:9,
          opacity:0,
          cursor: "pointer",              
        },
      },

    },
    registercheckbox:{
      display:"flex",
      width: 'fit-content',
      "& MuiFormControlLabel-label":{
        color:"red"
      },
     
      "& svg":{
        width: "28px",
        height: "28px",
        fill:"#385a74",
        borderRadius: "8px",
        marginLeft: "-1px",
      },
      "& .PrivateSwitchBase":{
        padding: '0px 9px 9px 9px',
      },
    },
    signupBtn:{
      fontStyle:" normal",
      fontWeight:'bold',
      textTransform:'capitalize',
      fontFamily:'Archivo !important',
      fontSize:"16px",
      lineHeight:"17px",
      color:"#FFFFFF",
      textDecoration:"none",
      background: "linear-gradient(80.04deg, #FAB013 5.55%, #FF2E00 90.36%)",
      width:"100%",
      display:"block",
      textAlign:"center",
      padding:"16px 0px",
      marginTop: "5px",
      marginBottom: "30px",
      boxShadow: "0px 10px 30px rgba(122, 137, 254, 0.04)",
      borderRadius: "8px",

      "@media (max-width:576px)": {
        marginTop: "12px",
      },
    },
    signupSeparate:{
      textAlign:"center",
      position: "relative",
      width: "100%",
      borderTop:"1px solid #20425A",
      "& p":{
        position: "absolute",
        top:"-10px",
        fontWeight: 600,
        fontSize: "16px",
        lineHeight: "17px",
        color: "#708EA4", 
        margin: 0,
        left: "50%",
        transform: "translateX(-50%)",
        background: "#123752",  
        padding:"0 10px",  
        whiteSpace:"nowrap",  
      }
    },
    walletgroup:{
      marginTop:"25px",     
    },
    connctWalletBtn:{
      border: "1.4783px solid #708EA4",
      boxSizing: "border-box",
      borderRadius: "8px",
      color:"#708EA4",
      backgroundColor: "transparent",
      padding:"15px 20px",
      display:"flex",
      alignItems:"center",
      margin:"0 auto",
      height: "48px",
      fontFamily: "roboto !important",
      fontSize: "16px",
      lineHeight: "19px",
      textTransform: "capitalize",

      "& img":{marginRight: "10px",},
    },
    termsChecks:{
      position: "relative",

      "& .MuiFormControlLabel-label":{
        color:"transparent",
      },
      "& p":{
        fontFamily: 'Roboto',
        fontWeight: 400,
        fontSize: "16px",
        lineHeight: "19px",
        color: "#8C8FA7",
        margin:0,
        position:"absolute",
        top: "13px",
        left: "39px",

        "& .checkspan":{
          color: "#17B294",
          textTransform: "capitalize",
        },
      },
    },
    genderChecks:{
      position: "relative",

      "& .MuiFormControlLabel-label":{
        color:"#8C8FA7",
      },
      "& .MuiFormGroup-root":{
        flexDirection:'row',
      },
      "& .MuiRadio-root":{
        color:"rgb(56 90 116)",
      },
      "& .MuiRadio-colorSecondary.Mui-checked":{
        color:"rgb(56 90 116)",
      },      
    },
    toastsucsess:{       
        borderRadius: '4px',
        "& .MuiSnackbarContent-root":{
          background: 'green',
          borderRadius: '4px',
        },
    },
    toastfail:{     
      borderRadius: '4px',
      "& .MuiSnackbarContent-root":{
        background: 'red',
        borderRadius: '4px',
      },
    },
    registerforminput:{
      position: 'relative',
      paddingBottom:'14px',
      marginBottom: '5px',
    },
    registerformError:{
      color:'red',
      fontSize:'12px',
      margin: 0,
      position: 'absolute',
      bottom: 0,
      left: 0,
    },
    registerloader:{
      background:'rgba(0,0,0, 0.54)',
      position:'absolute',
      width:'100%',
      height:'100%',
      top:0,
      left:0,
      zIndex: 9,
    },
    loginProfile:{
      width:'100%',
      maxWidth:45,
      '& img':{
        width:'100%',
        margin:0
      },
    },
    userprofilemenu:{
      position:"relative",
      "& .MuiMenu-paper":{
        top: "80px !important",
        backgroundColor:"rgb(3 29 49)",
      },
    },
    chainselectmenu:{
      position:"relative",
      "& .MuiMenu-paper":{
        top: "80px !important",
        backgroundColor:"rgb(3 29 49)",
      },
    }
  })
);

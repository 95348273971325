import React from 'react';
import useStyles from "./styles";
import { Button, Typography } from "@material-ui/core";
import Container from "@material-ui/core/Container";
import ourPartner from "../../assets/icon/ourPartner.svg";

const Ourpartner = () => {
  const classes = useStyles();
  return (
    <div className={classes.ourpartner}>
                <h3>Our Partners</h3>
                <p>Our team of Happiness Engineers works remotely from 58 countries providing customer support across multiple time zones.</p>
                <div className={classes.ourpartnerimg}>
                 <img src={ourPartner} alt="ourPartner" />
               </div>
               <div className={classes.tokenStrem}>
               <Container>
                 <div className={classes.tokenStremText}>
                 <Typography>
                 TokenStream - the <strong>most customizable API platform</strong> for all Crypto <strong>News.</strong>  
                 </Typography>
                 <Button>GET STARTED</Button>
                 </div>
                 </Container>
               </div>
              </div>
  )
}
export default Ourpartner;
import React, { useContext } from "react";
import { Link, NavLink, RouteComponentProps, withRouter } from "react-router-dom";
import Button from "@material-ui/core/Button";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
import BarChartIcon from "@material-ui/icons/BarChart";
import MoreIcon from "@material-ui/icons/MoreVert";
import useStyles from "./styles";
import logoIcon from "../../assets/icon/tokenstremfooter.svg";
import { Avatar, Box, CircularProgress, Select, Tooltip, Typography } from "@material-ui/core";
import { useQuery } from "react-query";
import { GetAllChains } from "../../services/chain";
import { Context } from "../../utils/Context";
import RegisterModal from "./RegisterModal";
import userProfile from "../../assets/icon/1.jpg";
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import SettingsIcon from '@material-ui/icons/Settings';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import LibraryBooksIcon from '@material-ui/icons/LibraryBooks';
const Header = ({ history }: RouteComponentProps) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] =
    React.useState<null | HTMLElement>(null);
  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);
  const [, setAnchorElPopper] = React.useState(null);
  const { geckoID, setGeckoID, setInitialLoad } = useContext(Context);
  const [show, setShow] = React.useState(false);
  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(null);
  const handleOpen = () => {
    setShow(true);
  };

  const handleClose = () => {
    setShow(false);
  };

  // const handleProfileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
  //   setAnchorEl(event.currentTarget);
  //   setAnchorElPopper(null);
  // };
  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };
  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };
  const handleLogout = () => {
    localStorage.removeItem("checkLogin");
    localStorage.removeItem("currentUser");
    history.push("/app");
  }

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };
  const handleMobileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setMobileMoreAnchorEl(event.currentTarget);
    setAnchorElPopper(null);
  };
  const menuId = "primary-search-account-menu";

  const { data, isLoading } = useQuery(
    ["fetch-all-chains"],
    () => GetAllChains(),
    {
      onSuccess: ({ data }) => {
        setGeckoID({ id: data[0]?.gecko_id, label: data[0]?.title });
        setInitialLoad(false);
      },
    }
  );

  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      id={menuId}
      keepMounted
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <MenuItem onClick={handleMenuClose}>Dashboard</MenuItem>
      <MenuItem><Button className={classes.RegisterMob} onClick={() => setShow(true)}>Register</Button></MenuItem>
    </Menu>
  );

  const mobileMenuId = "primary-search-account-menu-mobile";
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
      className={classes.mobileMenuTo}
    >
      <MenuItem>
        <NavLink className="navbar-item" to="/dashboard">
          <LibraryBooksIcon /> API Documentation
        </NavLink>
      </MenuItem>
      <MenuItem>
        <NavLink className="navbar-item" to="/dashboard">
          <BarChartIcon /> Dashboard
        </NavLink>
      </MenuItem>
      {localStorage.getItem("currentUser") ? <div>
        <MenuItem >
          <NavLink className="navbar-item" to="/dashboard">
            <AccountCircleIcon /> Profile
          </NavLink>
        </MenuItem>
        <MenuItem >
          <NavLink className="navbar-item" to="/dashboard">
            <SettingsIcon /> Settings
          </NavLink>
        </MenuItem>
        <MenuItem>
          <NavLink className="navbar-item" to="/dashboard">
            <ExitToAppIcon /> Logout
          </NavLink>
        </MenuItem>
      </div> :
        <MenuItem>
          <Button className={classes.RegisterMob} onClick={() => setShow(true)}>Register</Button>
        </MenuItem>
      }
    </Menu>
  );

  const handleSelectChange = (event: any) => {
    setGeckoID({
      id: event.target.value,
      label: event.nativeEvent.target.innerText,
    });
  };


  return (
    <div className={classes.grow}>
      <AppBar className={classes.header} position="static">
        <Toolbar>
          <div className={classes.logoIcon} onClick={() => history.push("/app")}>
            <img src={logoIcon} alt="Logo" />
          </div>

          <div className={classes.grow} />
          <div >
            <Link to='/' className={classes.dashboardmenu}>API Documentation</Link>
            <Link to='/dashboard' className={classes.dashboardmenu}>Dashboard</Link>
          </div>

          {isLoading ? (
            <CircularProgress size={30} />
          ) : (
            <>
              <div className={classes.chainselected}>
                <span className={classes.chainLabel}>Chain</span>
                <Select
                  className={classes.chainSelect}
                  defaultValue={data?.data[0]?.gecko_id}
                  value={geckoID?.id}
                  onChange={handleSelectChange}
                >
                  {data?.data?.map((chain, index) => (
                    <MenuItem value={chain.gecko_id} key={index} className={classes.chainselectmenu}>{chain.title}</MenuItem>
                  ))}
                </Select>
              </div>
            </>
          )}
          <div >
            {localStorage.getItem("currentUser") ? <>
              <Box sx={{ flexGrow: 0 }}>
                <Tooltip title="Open settings">
                  <IconButton onClick={handleOpenUserMenu}>
                    <Avatar className={classes.loginProfile} alt="Remy Sharp" src={userProfile} />
                  </IconButton>
                </Tooltip>
                <Menu
                  id="menu-appbar"
                  anchorEl={anchorElUser}
                  className={classes.userprofilemenu}
                  open={Boolean(anchorElUser)}
                  onClose={handleCloseUserMenu}
                >

                  <MenuItem key={'profile'} onClick={() => { handleCloseUserMenu(); history.push("/profile"); }}>
                    <Typography>Profile</Typography>
                  </MenuItem>
                  <MenuItem onClick={handleCloseUserMenu}>
                    <Typography>Settings</Typography>
                  </MenuItem>
                  <MenuItem key={'logout'} onClick={handleLogout}>
                    <Typography>Logout</Typography>
                  </MenuItem>

                </Menu>
              </Box>
            </> :
              <Button className={classes.Register} onClick={() => setShow(true)}>Register</Button>
            }
          </div>
          {/* <div className={classes.sectionDesktop}>
            <div className={classes.rigthIconsHeader}>
              <span className={classes.mianHeaderIcon}>
                <IconButton
                  className={classes.chipwallet}
                  edge="end"
                  aria-label="account of current user"
                  aria-controls={menuId}
                  aria-haspopup="true"
                  onClick={handleProfileMenuOpen}
                  color="inherit"
                >
                  <MoreHorizIcon />
                </IconButton>
              </span>
            </div>
          </div>  */}

          {/* <div className={classes.headerIcons}>

            <img src={cartCompareIcon}/>
            <img src={timeIcons}/>

          </div> */}

          <div className={classes.sectionMobile}>
            <IconButton
              aria-label="show more"
              aria-controls={mobileMenuId}
              aria-haspopup="true"
              onClick={handleMobileMenuOpen}
              color="inherit"
            >
              <MoreIcon />
            </IconButton>
          </div>
        </Toolbar>
      </AppBar>
      {renderMobileMenu}
      {renderMenu}

      <RegisterModal handleOpen={handleOpen} show={show} handleClose={handleClose} />
    </div>
  );
};

export default withRouter(Header);


import React from 'react';
import Spin from '../../../assets/icon/spinner.svg';
import useStyles from "../../Header/styles";
interface Props {
  small?: boolean;
}
const Loader = ({ small }: Props) => {
  return small ? (
    <div id="initialLoader">
      <div className="preloarder">
        <div className="circles-to-rhombuses-spinner">
          <div className="circle" />
          <div className="circle" />
          <div className="circle" />
        </div>
      </div>
    </div>
  ) : (
    <div id={`preloader`}>
      <div className="preloarder">
        <div className="circles-to-rhombuses-spinner">
          <div className="circle" />
          <div className="circle" />
          <div className="circle" />
        </div>
      </div>
    </div>
  );
};
// function Loader(){
//   const classes = useStyles();
//   return(
//     <div className={classes.spinnerclass}>
//       <img src={Spin} alt="loading.." />
//     </div> 
//   )
// }
export { Loader };

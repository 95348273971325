import React from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import useStyles from "../Header/styles";

export default function Toaster(props) {
  const classes = useStyles();

  return (
    <div>
     {props.open === true && props.data.status=== true ?
      <Snackbar
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        className={classes.toastsucsess}
        open={props.open}
        autoHideDuration={3000}
        onClose={props.handleClose}
        message={props.data.message}
        action={
          <React.Fragment>           
            <IconButton size="small" aria-label="close" color="inherit" onClick={props.handleClose}>
              <CloseIcon fontSize="small" />
            </IconButton>
          </React.Fragment>
        }
      />
      :
      (props.open === true && props.data.status === false) ?
      <Snackbar
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        className={classes.toastfail}
        open={props.open}
        autoHideDuration={3000}
        onClose={props.handleClose}
        message={props.data.message}
        action={
          <React.Fragment>           
            <IconButton size="small" aria-label="close" color="inherit" onClick={props.handleClose}>
              <CloseIcon fontSize="small" />
            </IconButton>
          </React.Fragment>
        }
      />: ""
}
    </div>
  );
}
import React , {useState} from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import useStyles from "./styles";
import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import Checkbox from '@material-ui/core/Checkbox';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import TextField from '@material-ui/core/TextField';
import WalletIcon from '../../assets/icon/walet.svg';
import UserIcon from '../../assets/icon/userr.svg';
import EmailIcon from '../../assets/icon/emaill.svg';
import PassIcon from '../../assets/icon/passs.svg';
import calenderIcon from '../../assets/icon/calendar.png';
import { serviceApi } from '../../api/Services';
import { Loader } from '../atoms';
import Toaster from '../toaster/Toaster';
import { useHistory } from 'react-router-dom';

function RegisterModal({handleOpen, show, handleClose}) {
  const classes = useStyles();
  const [toast, settoastOpen] = useState({show:false, status:false,message:''});
  const history = useHistory();
  const handletoastClose = (event: React.SyntheticEvent | React.MouseEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    settoastOpen({show:false, status:false,message:''});
  };
  let defaultData = {
        "fullname": "",
        "emailAddress": "",
        "gender": "M",
        "dateOfBirth": "",
        "password": ""
      };
  const[errors,setErrors]=useState({});
  const[loginErrors,setLoginErrors]=useState({});
  const[check, setCheck] = useState(false);
  const[login, setLogin] = useState(false);
  const[formData,setFormData]=useState(defaultData);
  const[loginForm,setLoginform]=useState({"emailAddress":"","password":""});
  const checkHandler = () =>{ setCheck(!check); }
  const[disabled,setDisabled]=useState(false);
  const[loader,setLoader]=useState(false);
  const handleRegister = async()=>{
    let data = serviceApi.validateError(formData);
    if(Object.keys(data).filter(x => data[x] === true).length > 0){ setErrors(data); return;} else{ setErrors({})} 	
    if(disabled === true){ return;}
    setLoader(true);
    setDisabled(true);
    formData.dateOfBirth = formData.dateOfBirth+"T00:00:00.000Z";
    let result = await serviceApi.post('/auth/register',formData);
   // console.log("result",result);
    if(result.status !== false){
      setFormData(defaultData);
     //true,false
     if(result['data']['data'].status === 201){
      settoastOpen({show:true, status:true,message:'Successfully registered your account!'});
     } else{
      settoastOpen({show:true, status:false,message:result['data']['data'].message});
     }
     
    }else{
      console.log(result)
      settoastOpen({show:true, status:false,message:result['error']['data'].message})
    }
    setDisabled(false);
    setLoader(false);  
  }
  const handleLogin = async()=>{
    let data = serviceApi.validateError(loginForm);
    if(Object.keys(data).filter(x => data[x] === true).length > 0){ setLoginErrors(data); return;} else{ setLoginErrors({})} 	
    if(disabled === true){ return;}
    setLoader(true);
    setDisabled(true);
    let result = await serviceApi.post('/auth/login',loginForm);
     console.log("result",result);
    if(result.status !== false){
      handleClose();
      setLoginform({"emailAddress":"","password":""});
      localStorage.setItem("currentUser",JSON.stringify(result['data']['data']['data']));
      history.push("/dashboard");
    }else{
      console.log(result)
      settoastOpen({show:true, status:false,message:result['error']['data'].message})
    }
    setDisabled(false);
    setLoader(false);  
  }
    return (
      <div >        
        <Dialog
          open={show}
          onClose={handleClose}
          maxWidth="sm"
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          className={classes.registerDialog}
        >
          {loader && <div className={classes.registerloader}><Loader small={true}  /></div>}
          {/* <DialogTitle id="alert-dialog-title">{"Use Google's location service?"}</DialogTitle> */}
          <DialogContent>
            {login ? 
            
            <>
           
              <h3>Log in to an account</h3>
              <p className="subtitle">Don't have an account? <span className="subtitlespan" onClick={()=>setLogin(false)}>Sign up</span></p>

              <form>     
              <div className={classes.registerforminput}>         
              <FormControl className={classes.registerformControl}>          
                <Input id="name1-simple" placeholder='Email Address'
                 value={loginForm.emailAddress}
                 onChange={(e)=>setLoginform({...loginForm,emailAddress:e.target.value})}
                />
                <img src={EmailIcon}  alt="emailicon"/>
              </FormControl>
              {(loginErrors && loginErrors.hasOwnProperty('emailAddress')) && <p className={classes.registerformError}>Please fill required field!</p>}
              </div>
              <div className={classes.registerforminput}>
              <FormControl className={classes.registerformControl}>          
                <Input id="name2-simple" placeholder='Password' type='password'
                value={loginForm.password}
                onChange={(e)=>setLoginform({...loginForm,password:e.target.value})}
                />
                <img src={PassIcon} alt="passicon"/>
              </FormControl>              
              {(loginErrors && loginErrors.hasOwnProperty('password')) && <p className={classes.registerformError}>Please fill required field!</p>}
              </div>
              <Button onClick={handleLogin} className={classes.signupBtn}>
               Log In
              </Button>
              </form>
              
              <div className={classes.signupSeparate}>
                  <p>or Log in with</p>
              </div>

              <div className={classes.walletgroup}>
                <Button className={classes.connctWalletBtn}><img src={WalletIcon} alt="icons"/> Connect Wallet </Button>
              </div>
              </>
            
            
            :<>
           
              <h3>Sign up for an account</h3>
              <p className="subtitle">Already have an account? <span className="subtitlespan" onClick={()=>setLogin(true)}>Log In</span></p>
              
              <form>
              <div className={classes.registerforminput}>
                <FormControl className={classes.registerformControl}>          
                  <Input id="name3-simple" placeholder='Full Name'
                  value={formData.fullname}
                  onChange={(e)=>setFormData({...formData,fullname:e.target.value})}
                  />
                  <img src={UserIcon} alt="usericon" />               
                </FormControl> 
                {(errors && errors.hasOwnProperty('fullname')) && <p className={classes.registerformError}>Please fill required field!</p>}
              </div>
              
              <div className={classes.registerforminput}>
                <FormControl className={classes.registerformControl}>          
                  <Input id="name4-simple" placeholder='Email Address'
                  value={formData.emailAddress}
                  onChange={(e)=>setFormData({...formData,emailAddress:e.target.value})}
                  />
                  <img src={EmailIcon}  alt="emailicon"/>                
                </FormControl>
                {(errors && errors.hasOwnProperty('emailAddress')) && <p className={classes.registerformError}>Please fill required field!</p>}
              </div>
             
             <div className={classes.registerforminput}>
              <FormControl className={classes.registerformControl}>          
                  <Input id="name5-simple" placeholder='Password'
                   type='password'
                  value={formData.password}
                  onChange={(e)=>setFormData({...formData,password:e.target.value})}
                  />
                  <img src={PassIcon} alt="passicon"/>               
                </FormControl>
                {(errors && errors.hasOwnProperty('password')) && <p className={classes.registerformError}>Please fill required field!</p>}
             </div>
              
              <div className={classes.registerforminput}>
                <FormControl className={classes.registerformControl}>          
                  <TextField
                  id="date"
                  type="date"
                  placeholder="Enter DOB"
                  value={formData.dateOfBirth}
                  onChange={(e)=>setFormData({...formData,dateOfBirth:e.target.value})}
                  />
                  <img src={calenderIcon} alt="Dob-icon"/>               
                </FormControl>
                {(errors && errors.hasOwnProperty('dateOfBirth')) && <p className={classes.registerformError}>Please fill required field!</p>}
              </div>
              <div className={classes.genderChecks}>            
              <RadioGroup aria-label="gender" name="gender1"
              value={formData.gender}
              onChange={(e)=>setFormData({...formData,gender:e.target.value})}
              className={classes.genderChecks}>
                      <FormControlLabel value="F" control={<Radio />} label="Female" />
                      <FormControlLabel value="M" control={<Radio />} label="Male" />
                </RadioGroup>
              </div>
              <div className={classes.termsChecks}>            
                <FormControlLabel className={classes.registercheckbox} control={
                  <Checkbox checked={check} value="checkedA" onChange={checkHandler}/> 
                } 
                label="I agree with Terms and Privacy" />
                <p > I agree with <span className="checkspan">Terms</span> and <span className="checkspan">Privacy</span> </p> 
              </div>
              <Button onClick={handleRegister} className={classes.signupBtn}>
               Sign Up
              </Button>
              </form>
              
              <div className={classes.signupSeparate}>
                  <p>or sign up with</p>
              </div>

              <div className={classes.walletgroup}>
                <Button className={classes.connctWalletBtn}><img src={WalletIcon} alt="icons"/> Connect Wallet </Button>
              </div>
              </>}
          </DialogContent>         
        </Dialog>
        
        <Toaster open={toast.show} handleClose={handletoastClose} data={toast}/>
      </div>
    );  
}

export default RegisterModal;

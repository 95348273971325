import { createContext, useState } from "react";

type ContextProps = {
  geckoID: any;
  setGeckoID: any;
  initialLoad: boolean;
  setInitialLoad: any;
};

export const Context = createContext<Partial<ContextProps>>({});

export const ContextProvider = (props) => {
  const [geckoID, setGeckoID] = useState({ id: "", label: "" });
  const [initialLoad, setInitialLoad] = useState(true as boolean);

  return (
    <Context.Provider
      value={{
        geckoID,
        setGeckoID,
        initialLoad,
        setInitialLoad,
      }}
    >
      {props.children}
    </Context.Provider>
  );
};

import { makeStyles, createStyles } from '@material-ui/core/styles';

export default makeStyles((theme : any) => createStyles({
    root:{

    },
    
    loginScreen:{
        width:400,
        margin:'0 auto',
        textAlign:'center',
        marginTop:50,
        marginBottom:50,
      '& form':{
        marginBottom:'24px',
        width:400,
        display: 'block',
        padding: '22px 25px',
        background: '#1a1a23',
        borderRadius:10,
        marginTop:20,
      },
      '& .MuiInput-underline:before':{
        display:'none',
      },
       '& .MuiInput-underline:after':{
        display:'none',
      },
      '& .MuiButtonBase-root':{
            width:'100%',
            color:'#fff',
            border:'#4A4DE6 !important',
            background:'#4A4DE6 !important',
            fontSize:14,
            textTransform:'none',
            marginTop:30,
            height:40,
            marginBottom:15,
      },
      '& .MuiTextField-root':{
            width:'100%',
      },
      '& .MuiInputBase-input':{
        color:'#fff',
        fontSize: 14,
        padding:'11px 11px',
        borderRadius:'4px !important',
        border: '1px solid rgb(255 255 255 / 44%)',
        background:'rgb(255 255 255 / 8%)',
      },
      '& .socialLogin':{
        background:'#fff !important',
      },
    },
    loginInnersec:{
        display:'block',
        fontSize:22,
        marginTop:20,
        color: '#fff',
        width:'100%',
        textAlign:'center',
        marginBottom:20,
        fontWeight:500,
    },
    otherOpt:{
    '& button':{
        marginBottom:'15px !important',
        background:'#fff !important',
        color:'rgb(66, 82, 110) !important',
        fontWeight: 'bold',
        padding:'8px 20px',
        margin: '0 !important',
    },
    '& .MuiButton-label':{
        display:'inline-block',
        width:'100%',
    },
    '& img':{
        float:'left',
        width:18,
        height:18,
        marginTop:3,
    },
    },
    oropt:{
      color:'rgb(151, 160, 175)',
        fontSize:12,
        marginBottom:25,
        display:'block',
        marginTop:25,
        textAlign:'center',
    },
}));

import React, { useState } from "react";
import { withRouter, RouteComponentProps,useHistory } from "react-router-dom";
import {Grid} from "@material-ui/core";
import useStyles from "./styles";
import logoIcon from '../../assets/icon/logo.png';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
const Login = (props : RouteComponentProps) => {
  var classes = useStyles();
  const[userName,setUsername] = useState('');
  const history = useHistory();
 const login = ()=>{
	 if(userName !== "tokenstreamadmin" ){ alert("Please check username"); return false;}
	 localStorage.setItem('checkLogin', 'tokenstreamadmin');
	 history.push("/dashboard");
 }
 console.log('checkLogin',localStorage.getItem('checkLogin'));

  return (
    <Grid container className={classes.root}>
    <div className={classes.loginScreen}>
      <img src={logoIcon} alt=""/>
      <form>
      <div className={classes.loginInnersec}><span>Sign in</span></div>
      <TextField  name="username"  type="password"  placeholder="Type your password here" id="username" onChange = {(e)=>{ setUsername(e.target.value)}}/>
		<Button onClick={(e)=>{ e.preventDefault(); login()}}>
			Log in
		</Button>
    </form>
    </div>
    </Grid>
  );
}

export default withRouter(Login);
import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { Grid } from "@material-ui/core";
import useStyles from "./styles";
import Container from "@material-ui/core/Container";
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/footer";
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Ourpartner from "../AppDashboard/Ourpartner";
import Link from '@material-ui/core/Link';
import Avtaricon from "../../assets/icon/User.svg";
import Badge from '@material-ui/core/Badge';
import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import UserIcon from '../../assets/icon/userr.svg';
import EmailIcon from '../../assets/icon/emaill.svg';
import PassIcon from '../../assets/icon/passs.svg';
import Recttab from "../../assets/icon/recttab.svg";
import { serviceApi } from '../../api/Services';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import calenderIcon from '../../assets/icon/calendar.png';
import Toaster from '../../components/toaster/Toaster';
import { Loader } from "../../components/atoms";


const Profile = () => {
  var classes = useStyles();
  const [toast, settoastOpen] = useState({show:false, status:false,message:''});
  const[loader,setLoader]=useState(false);
  const handletoastClose = (event: React.SyntheticEvent | React.MouseEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    settoastOpen({show:false, status:false,message:''});
  };
  let defaultData = {
    "fullname": '',
    "gender": '',
    "dateofbirth": '',   
  };
  const[userData, setUserData] = useState(defaultData);
  //const[message,setMessage]=useState('');
  const[disabled,setDisabled]=useState(false);
  const getUserDetails = async() =>{
    let res = await serviceApi.get('/user/me');
    if(res.status !== false){
      let data = res['data'].data;
      let defaultUserData = {
        "fullname":  data && data['fullname'],
        "gender":  data && data['gender'],
        "dateofbirth": data && data['dateofbirth'].split("T")[0],
        "emailAddress":data && data['loginDetails'] && data['loginDetails']['emailAddress'],
      };
      setUserData(defaultUserData);
      
    }
  }

  useEffect(() => {
    getUserDetails();
  }, []);
  const handleParams = (key,value)=>{
	let arr:any = {...userData};
	arr[key]=value;
	setUserData(arr);
  }

  const handleProfileUpdate = async() =>{ 
    if(disabled === true){ return;}
    setDisabled(true); 

    if(userData.hasOwnProperty("password") && userData["password"] !== "" && userData["password"] !== userData["newPassword"]){
      //setMessage("Password can't match");
      setDisabled(false);
      settoastOpen({show:true, status:false,message:"Password can't match"});
      return;
    }else if(userData.hasOwnProperty("password") && userData["password"] === ""){
       delete userData['password'];
       delete userData['newPassword'];
    }   
    setLoader(true); 
    let formData = {...userData};
    formData['dateofbirth'] = formData['dateofbirth']+"T00:00:00.000Z"; 
    let result = await serviceApi.patch('/user/me/change',formData);
    if(result.status !== false){
      settoastOpen({show:true, status:true,message:"Successfully updated your profile"});
    }else{
      settoastOpen({show:true, status:false,message:result['error']['data']['message']});
    }
    setLoader(false);
    setDisabled(false);
  }
  console.log("userData", userData)
  return (
    <Grid className={classes.root}>
      <Header />
      {loader && <div className={classes.registerloader}><Loader small={true}  /></div>}
      <div className={classes.profile}>
        <div className={classes.proSection}>
          <Container >
            <div className={classes.profilepage}>
              <Breadcrumbs aria-label="breadcrumb" separator="›" className={classes.breadcrumb}>
                <Link color="inherit" href="/app" className={classes.breadlink}>
                  Home
                </Link>
                <Link
                  color="textPrimary"
                  href="/profile"
                  aria-current="page"
                  className={classes.breadlinkActive}
                >
                  Profile
                </Link>
              </Breadcrumbs>
              <Grid container className={classes.profileinner}>
                <Grid item xs={12} lg={3} >
                  <div className={classes.userAvatarBox}>
                    <div className="avtaricon">
                      <img src={Avtaricon} alt="avtar" />
                      <Badge color="primary"></Badge>
                    </div>
                    <div className="userdetalis">
                      <h2>{userData && (userData['fullname'])}</h2>
                      <p>{userData && userData['role']}</p>
                    </div>
                  </div>
                </Grid>
                <Grid item xs={12} lg={9} >
                  <div className={classes.userDataForm}>
                    <p>Personal Info</p>
                    <form>
                      <Grid container spacing={4}>
                        <Grid item xs={12} lg={6}>
                          <div className={classes.userforminput}>
                            <FormControl className={classes.userformControl}>
                              <Input id="firstname" placeholder='First Name' 
                              value={userData && (userData['fullname'] || '')}
							                onChange={(e)=>handleParams('fullname',e.target.value)}
                              />
                              <img src={UserIcon} alt="usericon" />
                            </FormControl>
                          </div>
                        </Grid>
                        <Grid item xs={12} lg={6}>
                        <div className={classes.registerforminput}>
                          <FormControl className={classes.registerformControl}>          
                            <TextField
                            id="date"
                            type="date"
                            placeholder="Enter DOB"
                            value={userData && (userData['dateofbirth'] || '')}
							              onChange={(e)=>handleParams('dateofbirth',e.target.value)}
                            
                            />
                            <img src={calenderIcon} alt="Dob-icon"/>               
                          </FormControl>                          
                        </div>
                        </Grid>
                      </Grid>

                      <Grid container spacing={4}>
                        <Grid item xs={12} lg={6}>
                          <div className={classes.userforminput}>
                            <FormControl className={classes.userformControl}>
                              <Input id="name6-simple" placeholder='Email Address' 
                                value={userData && userData['emailAddress']} 
							                disabled={true}
                              />
                              <img src={EmailIcon} alt="emailicon" />
                            </FormControl>
                          </div>
                        </Grid>
                        <Grid xs={12} item lg={6}>
                        <div className={classes.genderChecks}>            
                          <RadioGroup aria-label="gender" name="gender1"
                          value={userData && (userData['gender'] || '')}  
							
                          className={classes.genderChecks}>
                                  <FormControlLabel value="F" control={<Radio />} label="Female" onChange={(e)=>handleParams('gender','F')}/>
                                  <FormControlLabel value="M" control={<Radio />} label="Male" onChange={(e)=>handleParams('gender','M')}/>
                            </RadioGroup>
                          </div>
                        </Grid>
                      </Grid>

                      <div>
                        <p>Change Password</p>
                        <Grid container spacing={4}>
                          <Grid xs={12} item lg={6}>
                            <div className={classes.userforminput}>
                              <FormControl className={classes.userformControl}>
                                <Input id="cpassword" placeholder='Current Password' 
                                type="password"
                                 onChange={(e)=>handleParams('password',e.target.value)}
                                />
                                <img src={PassIcon} alt="passicon" />
                              </FormControl>
                            </div>
                          </Grid>
                          <Grid xs={12} item lg={6}>
                            <div className={classes.userforminput}>
                              <FormControl className={classes.userformControl}>
                                <Input id="npassword" placeholder='New Password' 
                                 type="password"
                                 onChange={(e)=>handleParams('newPassword',e.target.value)}
                                />
                                <img src={PassIcon} alt="passicon" />
                              </FormControl>
                            </div>
                          </Grid>
                        </Grid>
                      </div>

                      <Button className={classes.saveBtn} onClick={handleProfileUpdate}> Save </Button>
                    </form>
                  </div>
                </Grid>
              </Grid>
            </div>
          </Container>
          <div className={classes.lowerSection} style={{ backgroundImage: `url(${Recttab})` }}></div>
        </div>
      </div>
      <Toaster open={toast.show} handleClose={handletoastClose} data={toast}/>
      <Ourpartner />
      <Footer />
    </Grid>
  );
}

export default withRouter(Profile);
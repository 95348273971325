import React from "react";
import { AppBar, Container } from "@material-ui/core";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import { withRouter, RouteComponentProps } from "react-router-dom";
import useStyles from "../Header/styles";
import logoIcon from "../../assets/icon/tokenstremfooter.svg";
import TelegramIcon from "@material-ui/icons/Telegram";
import TwitterIcon from "@material-ui/icons/Twitter";
import Dicord from "../../assets/icon/discord.png";

//import "./footer.scss";

const Footer = ({ history }: RouteComponentProps) => {
  const classes = useStyles();
  return (
    <div className={classes.grow}>
      <AppBar className={classes.footer} position="static">
        <div className={classes.logo_wrapper}>
              <div className={classes.app_footer_logo}>
                <img src={logoIcon} alt="" />
              </div>
              </div>
        <Container className={classes.footerinner}>
          <div className={classes.footer_wrapper}>        
            <div className={classes.footer_content}>
              <div className={classes.footer_content_items}>
                <h4>Who we are</h4>
                <a href="#/dashboard">
                  <span color="inherit">About Us</span>
                </a>
                <a href="#/dashboard">
                  <span color="inherit">Team</span>
                </a>
                <a href="#/dashboard">
                  <span color="inherit">Work With Us</span>
                </a>
              </div>
              <div className={classes.footer_content_items}>
                <h4>Resources</h4>
                <a href="#/dashboard">
                  <span color="inherit">indice</span>
                </a>
                <a href="#/dashboard">
                  <span color="inherit">API Docs</span>
                </a>
                <a href="#/dashboard">
                  <span color="inherit">Pricing</span>
                </a>
                <a href="/#/request-form">
                  <span color="inherit">List Your Project</span>
                </a>
              </div>
              <div className={classes.footer_content_items}>
                <h4>Other LInks</h4>
                <a href="#/terms">
                  <span color="inherit">Terms of use</span>
                </a>
                <a href="#/dashboard">
                  <span color="inherit">Privacy Releases</span>
                </a>
                <a href="#/dashboard">
                  <span color="inherit">FAQ</span>
                </a>
                <a href="#/dashboard">
                  <span color="inherit">Contact</span>
                </a>
              </div>
              <div className={classes.footer_content_items}>
                <h4>Social</h4>
                <a
                  href="https://twitter.com/token_stream"
                  target="_blank"
                  rel="noreferrer"
                >
                  <span color="inherit"><TwitterIcon /> Twitter</span>
                </a>
                <a
                  href="https://discord.gg/qXF6sq4a"
                  target="_blank"
                  rel="noreferrer"
                >
                  <span color="inherit"><img src={Dicord} alt="dicord"/> Discord</span>
                </a>
                <a
                  href="https://t.me/+sQOXFYtmX_E3MTY0"
                  target="_blank"
                  rel="noreferrer"
                >
                  <span color="inherit"><TelegramIcon /> Telegram</span>
                </a>
                {/* <a href="#">
                  <span color="inherit">Facebook</span>
                </a>
                <a href="#">
                  <span color="inherit">Instagram</span>
                </a> */}
              
              
              </div>
            </div>
           
          </div>
        </Container>
        <Typography
              className={`${classes.fttitle} titleSm`}
              variant="h6"
              noWrap
            >
              © 2022 TokenStream. All Rights Reserved.
            </Typography>
      </AppBar>
    </div>
  );
};

export default withRouter(Footer);

import { makeStyles, createStyles } from '@material-ui/core/styles';

export default makeStyles((theme : any) => createStyles({
    root:{
      paddingTop: '104px',
    },
    profilepage:{
     marginTop: '20px',
    },
    profile:{
      overflow: 'hidden',
      paddingBottom: '100px',
    },
    proSection:{
      position:'relative',
    },
    lowerSection:{
      right: '-44px',
      width: '478px',
      bottom: '-100px',
      height: '478px',
      zIndex: -1,
      position: 'absolute',
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '0px 0px',
    },
    breadcrumb:{
      "& .MuiBreadcrumbs-separator":{
        color: '#708EA4;'
      }
    },
    breadlink:{
      fontFamily: 'Roboto',
      fontWeight: 500,
      fontSize: '18px',
      lineHeight: '21px',
      color: '#4C5C68'
    },
    breadlinkActive:{
      fontFamily: 'Roboto',
      fontWeight: 500,
      fontSize: '18px',
      lineHeight: '21px',
      color: '#708EA4;'
    },
    profileinner:{
      marginTop: '34px',
    },
    userAvatarBox:{
      padding: '30px',
      background: '#032137',
      border: '1px solid #0C436A',
      borderRadius: '13px',
      textAlign: 'center',     

      "& .avtaricon":{
        position: 'relative',
        width:'150px',
        height: '150px',
        borderRadius: '50%',
        margin: '0 auto',

        "& .MuiBadge-root":{
          position: 'absolute',
          bottom: '20%',
          right:'8px',
          width:'9px',
          height: '9px',
          boxSizing: 'border-box',
          background:'#49C96D',
          border: '2px solid #fff',
          borderRadius: '50%',        
        }
      },
      "& .userdetalis":{
        marginTop: '20px',

        "& h2":{
          fontFamily: 'Archivo',
          fontWeight: '700',
          fontSize: '36px',
          lineHeight: '44px',         
          color: '#FFFFFF',
          margin: 0,
        },

        "& p":{
          fontFamily: 'Roboto',
          fontWeight: 400,
          fontSize: '18px',
          lineHeight: '21px',
          color: '#708EA4',
          textShadow: '0px 2px 0px #04121D',
          margin: 0,
        }
      }
    },
    genderChecks:{
      position: "relative",

      "& .MuiFormControlLabel-label":{
        color:"#8C8FA7",
      },
      "& .MuiFormGroup-root":{
        flexDirection:'row',
      },
      "& .MuiRadio-root":{
        color:"rgb(56 90 116)",
      },
      "& .MuiRadio-colorSecondary.Mui-checked":{
        color:"rgb(56 90 116)",
      },      
    },
    registerforminput:{
      position: 'relative',
      paddingBottom:'14px',
      marginBottom: '5px',
    },
    registerformControl:{
      width: "100%",
      maxWidth: "500px",     
      background: "#032137",
      border: "1.4783px solid #20425A",     
      borderRadius: "8px",
      position: 'relative',
      "& .MuiInput-underline:before":{
        borderBottom: '1px solid rgb(0 0 0 / 0%)',
      },
      "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
        borderBottom: '0px none',
      },
      "& img":{
        width:"17px",
        height:"17px",
        position:"absolute",
        top: "15px",
        left: "10px",
        cursor: "pointer",
      },
      
      "& input":{
        height: "46px",
        boxSizing: "border-box",
        padding: "10px 40px",
        fontWeight: 400,
        fontSize: "14px",
        lineHeight: "16px",
        color: "#285271",
        position:"relative",

        "&::-webkit-calendar-picker-indicator":{
          fill:"#285271",
          fontSize:"20px",
          filter: 'invert(0.5)', 
          position:"absolute",  
          top:"12px",
          left:"7px",
          zIndex:9,
          opacity:0,
          cursor: "pointer",              
        },
      },

    },
    userDataForm:{
      marginLeft: '40px',
      "& p":{
        fontFamily: 'Athiti !important',
        fontWeight: 400,
        fontSize: '18px',
        lineHeight: '29px',
        color: '#708EA4',
        margin: 0,
        marginBottom: '10px',
      },
      "@media (max-width:1200px)": {
        marginLeft: "0",
        marginTop: '30px',

        "& .MuiGrid-spacing-xs-4 > .MuiGrid-item":{
          padding: '0 16px',
        },
        "& .MuiGrid-spacing-xs-4":{
          margin: '0 -16px',
        }
      },
    },
    userforminput:{
      position: 'relative',
      marginBottom: '14px',

      "@media (max-width:1200px)":{
        marginBottom: '20px',
      }
    },
    userformControl:{
      width: "100%",
          
      background: "#032137",
      border: "1.4783px solid #20425A",     
      borderRadius: "8px",
      position: 'relative',
      "& .MuiInput-underline:before":{
        borderBottom: '1px solid rgb(0 0 0 / 0%)',
      },
      "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
        borderBottom: '0px none',
      },
      "& img":{
        width:"17px",
        height:"17px",
        position:"absolute",
        top: "15px",
        left: "10px",
        cursor: "pointer",
      },
      "& .MuiInputBase-input.Mui-disabled":{
        cursor:'no-drop',
      },
      "& input":{
        height: "46px",
        boxSizing: "border-box",
        padding: "10px 40px",
        fontWeight: 400,
        fontSize: "14px",
        lineHeight: "16px",
        color: "#285271",
        position:"relative",        

        "&:placeholder":{
          color: '#285271 !important',
        },
        "&:focus-visible":{
          outline: 'none !important',
        },

        "&::-webkit-calendar-picker-indicator":{
          fill:"#285271",
          fontSize:"20px",
          filter: 'invert(0.5)', 
          position:"absolute",  
          top:"12px",
          left:"7px",
          zIndex:9,
          opacity:0,
          cursor: "pointer",              
        },
      },
    },
    saveBtn:{
      fontStyle:" normal",
      fontWeight:'bold',
      textTransform:'capitalize',
      fontFamily:'Archivo !important',
      fontSize:"20px",
      lineHeight:"22px",
      color:"#FFFFFF",
      textDecoration:"none",
      background: "linear-gradient(80.04deg, #FAB013 5.55%, #FF2E00 90.36%)",
      display:"block",
      textAlign:"center",
      padding:"20px 40px",
      marginTop: "40px",
      boxShadow: "0px 10px 30px rgba(122, 137, 254, 0.04)",
      borderRadius: "244px",

      "@media (max-width:1200px)": {
        marginTop: "20px",
      },
    },
    registerloader:{
      background:'rgba(0,0,0, 0.54)',
      position:'absolute',
      width:'100%',
      height:'100%',
      top:0,
      left:0,
      zIndex: 9,
    },
  }))